<template>
  <div class="px-2 mt-1">
    <header-slot>
      <template #actionsButtons>
        <portal-target name="actionsButtons_header" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-if="isCeo || isSupervisor"
        :to="{ name: 'assignment-modification' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        ASSIGNMENT MODIFICATION
      </b-nav-item>
      <b-nav-item
        :active="isActive2"
        :to="{ name: 'keywords' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        ACCOUNTS
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <!-- <template v-if="isActive2">
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: 'keywords' }"
            exact
            active-class="bg-info box-shadow-info border-info info"
            exact-active-class="active"
            :link-classes="['sub-tab px-3', bgTabsNavs]"
          >
            KEYWORDS
          </b-nav-item>

          <b-nav-item
            :to="{ name: 'classification-ncr' }"
            exact
            active-class="bg-info box-shadow-info border-info info"
            exact-active-class="active"
            :link-classes="[bgTabsNavs, 'sub-tab px-3']"
          >
            CLASSIFICATION NCR
          </b-nav-item>
        </b-nav>
        <b-card
          no-body
          class="border-top-info border-3 border-table-radius px-0"
        >
        </b-card>
      </template> -->
      <router-view ref="routerViewTab" :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  computed: {
    isActive() {
      const routes = ["automatic", "manual"];
      return routes.includes(this.$route.name);
    },
    isActive2() {
      const routes = ["keywords", "classification-ncr"];
      return routes.includes(this.$route.name);
    },
  },
};
</script>
